


import {Component, Vue, Watch} from 'vue-property-decorator';
import IconMap from '@/_modules/icons/components/icon-map.vue';
import HelpCrunchService from '@/_services/help-crunch.service';
import {Action, Getter} from 'vuex-class';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import {TFile} from '@/_types/file.type';
import {TEditEventParams} from '@/_modules/events/api/event/event.api';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {TEventRequest} from '@/_modules/events/types/event.type';
import {TEvent} from '@/_types/event.type';
import CabinetExponentsTabs
  from '@/_modules/promo-cabinet/components/cabinet-exponents-tabs/cabinet-exponents-tabs.vue';
import {TLeaveFunction} from '@/_modules/promo/types/edit-form-store-state.type';
import {NavigationGuardNext, Route} from 'vue-router';
import {TEventSettings} from '@/_types/event-settings.type';

// See https://class-component.vuejs.org/guide/additional-hooks.html
Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {
    IconMap,
    EwButton,
    FormFileUploader,
    CabinetExponentsTabs,
  },
})
export default class CabinetEventMap extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Action('_eventStore/editEvent') editEvent: (params: TEditEventParams) => Promise<void>;
  @Action('_eventStore/updateMapImage') updateMapImage: (newMapImageUrl: string) => void;
  @Action('editFormStore/showConfirmLeavePopup') public showConfirmLeavePopup: (leaveFunction?: TLeaveFunction) => void;
  @Action('editFormStore/hideConfirmLeavePopup') public hideConfirmLeavePopup: () => void;
  @Action('editFormStore/leave') public leave: () => void;

  public localMapImage: string = '';
  public isUploading: boolean = false;
  public isSaving: boolean = false;

  public get hasUnsavedChanges(): boolean {
    return this.localMapImage !== this.mapImage;
  }

  public get isDeleteImageButtonVisible(): boolean {
    return !this.isSaving && this.mapImage && (this.localMapImage === this.mapImage);
  }

  public get mapImage(): string {
    return (this.event && this.event.map_image) || '';
  }

  public get isSaveButtonDisabled(): boolean {
    return !this.hasUnsavedChanges && (!!this.isUploading || !this.localMapImage || (this.localMapImage === this.mapImage));
  }

  @Watch('mapImage')
  private onMapImageChange(newVal: string): void {
    this.localMapImage = newVal || '';
  }

  public get isSupportChatEnabled(): boolean {
    return (this.eventSettings && this.eventSettings.layout && this.eventSettings.layout.is_support_chat_enabled) || false;
  }

  public get floorPlanSubtitleText(): string {
    if (this.isSupportChatEnabled) {
      return this.$t('eventMap.eventMapCreateInfo.text2') as string;
    }
    return this.$t('eventMap.eventMapCreateInfo.text2_noSupportChat') as string;
  }

  public mounted(): void {
    this.localMapImage = this.mapImage || '';
  }

  private openSupportChat(event: Event): void {
    if (event && event.target) {
      const helpCrunchInstance = HelpCrunchService._helpCrunch;
      if (!helpCrunchInstance) { // TODO: also check for instance.isInitialized and test it
        window.setTimeout(() => { this.openSupportChat(event); }, 1000);
        return;
      }
      helpCrunchInstance('openChat');
    }
  }

  public async onSave(): Promise<void> {
    this.isSaving = true;

    await this.editEvent({
      eventId: this.event.id,
      formData: this.getPatchEventPayload(),
    });

    this.updateMapImage(this.localMapImage);

    this.isSaving = false;
  }

  public onCancel(): void {
    this.localMapImage = this.mapImage || '';
  }

  public setAndDisplayFileUploaderError(/* data: any */): void {
    // todo
  }

  public setIsUploading(): void {
    this.isUploading = true;
  }

  public onUploaderIdle(): void {
    this.isUploading = false;
  }

  public onUploaderInput(value: TFile): void {
    this.isUploading = false;
    this.localMapImage = (value && value.url) || this.mapImage || '';
  }

  public getPatchEventPayload(): TEventRequest {
    return {
      title: this.event.title,
      date_start: DateTimeHelper.dateToApiDate(this.event.date_start),
      date_end: DateTimeHelper.dateToApiDate(this.event.date_end),
      time_region: this.event.time_region,
      map_image: this.localMapImage,
    };
  }

  public onDeleteImageClick(): void {
    this.localMapImage = '';
  }

  public beforeDestroy(): void {
    this.localMapImage = this.mapImage;
    this.hideConfirmLeavePopup();
  }

  public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.hasUnsavedChanges) {
      this.showConfirmLeavePopup(next);
    } else {
      this.localMapImage = this.mapImage;
      this.hideConfirmLeavePopup();
      next();
    }
  }

}
